import React, { lazy, Suspense } from "react";
import { Outlet } from "react-router-dom";
import AsideBar from "../components/AsideBar";
import FooterFront from "./FooterFront";
import HeaderFront from "./HeaderFront";
import HeaderFrontNew from "./HeaderFrontNew";
import FooterFrontNew from "./FooterFrontNew";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";

function LayoutOfFront() {
    return (
        <>
            {/* <HeaderFront /> */}
            <HeaderFrontNew />
            <Outlet />
            {/* <FooterFront/> */}
            <FooterFrontNew/>
        </>
    )
}

function LayoutOfAsideAndHeader() {
    return (
        <>
            <AsideBar />
            <Outlet />
        </>
    )
}


export const PageLoader = ({backend = false}) => {

    return (<>
        <SkeletonTheme>
           {backend && <Skeleton className="m-0 p-0" containerClassName="backend-skelten" style={{ display:'block' }} width={'100%'} height={"50px"} />}
           <Skeleton className="m-0 p-0" highlightColor="#e6e6e6" baseColor="#f9f9f9" style={{ display:'block' }} width={'100%'} height={"90vh"} />
        </SkeletonTheme>
    </>)
}

export { LayoutOfFront, LayoutOfAsideAndHeader}