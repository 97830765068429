import { ShowImage } from "../../components/ImageCropper";

function ImageUploadSoci (props) {
    
    const filterImages = (data, category, network) => {
        if (!Array.isArray(data)) {
            return [];
        }
        return data.filter(item => item?.category === category && item?.network === network);
    };
   
    let MainLogoImageUrl = filterImages(props.allImage, "LOGO", "ALL")[0]?.image_url || "";

    if(props.allImage.all_logo){
        MainLogoImageUrl =  props.allImage.all_logo.url;
    }

    let MainCoverImageUrl = filterImages(props.allImage, "COVER_PHOTO", "ALL")[0]?.image_url || "";
    if(props.allImage.all_cover){
        MainCoverImageUrl =  props.allImage.all_cover.url;
    }
    let MainPhotoImageUrl = filterImages(props.allImage, "PHOTO", "ALL")[0]?.image_url || "";
    if (!MainLogoImageUrl) {
        MainLogoImageUrl = MainPhotoImageUrl;
    }

    let GoogleLogoImageUrl = filterImages(props.allImage, "LOGO", "GMB")[0]?.image_url || "";
    if(props.allImage.google_logo){
        GoogleLogoImageUrl =  props.allImage.google_logo.url;
    }

    let GoogleCoverImageUrl = filterImages(props.allImage, "COVER_PHOTO", "GMB")[0]?.image_url || "";

    if(props.allImage.google_cover){
        GoogleCoverImageUrl =  props.allImage.google_cover.url;
    }

    let GooglePhotoImageUrl = filterImages(props.allImage, "PHOTO", "GMB")[0]?.image_url || "";
    
    if (!GoogleLogoImageUrl) {
        GoogleLogoImageUrl = GooglePhotoImageUrl;
    }

    let FacebookLogoImageUrl = filterImages(props.allImage, "LOGO", "FACEBOOK")[0]?.image_url || "";

    if(props.allImage.facebook_logo){
        FacebookLogoImageUrl =  props.allImage.facebook_logo.url;
    }

    let FacebookCoverImageUrl = filterImages(props.allImage, "COVER_PHOTO", "FACEBOOK")[0]?.image_url || "";
    if(props.allImage.facebook_cover){
        FacebookCoverImageUrl =  props.allImage.facebook_cover.url;
    }
    let FacebookPhotoImageUrl = filterImages(props.allImage, "PHOTO", "FACEBOOK")[0]?.image_url || "";
    if (!FacebookLogoImageUrl) {
        FacebookLogoImageUrl = FacebookPhotoImageUrl;
    }

 
    return (
        <>
           

            <div className="row mb-3 mt-4 border rounded pb-3 m-0">                
                <div className="bg-light rounded py-2 fw-medium text-dark">Upload Images</div>                
                <div className="col-sm-12">                    
                    <div className="row">
                        <div className="col-md-4 col-lg-3">
                            <h6 className="mb-0 mt-2 border py-2 px-2 rounded bg-light"><i class="bi bi-reception-4"></i> All Network</h6>
                        </div>
                        <div className="col-md-8 col-lg-9">
                            <div className="row g-4">
                                <div className="col-md-6">
                                    <div class="input-uploader">                                       
                                        <button type="button" className="mt-2 w-100 border btn btn-light text-primary" id="" data-bs-toggle="modal" data-bs-target="#image_crop_modal" onClick={() => props.setImageName('all_logo')}>
                                            <svg className="icon me-1" role="img"><use href="#icon_plus" /></svg>
                                            Add Logo
                                        </button>
                                        {props.allImage && MainLogoImageUrl ? 
                                            <ShowImage 
                                                name='all_logo' 
                                                src={MainLogoImageUrl} 
                                                cropImage={MainLogoImageUrl} 
                                                setCropImage={props.setAllImage} 
                                                classRemove={true}
                                            /> 
                                        : ''}
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div class="input-uploader">                                        
                                        <button type="button" className="mt-2 w-100 border btn btn-light text-primary" id="" data-bs-toggle="modal" data-bs-target="#image_crop_modal" onClick={() => props.setImageName('all_cover')}>
                                            <svg className="icon me-1" role="img"><use href="#icon_plus" /></svg>
                                            Add Cover
                                        </button>
                                        {props.allImage && MainCoverImageUrl ?  
                                            <ShowImage 
                                                name='all_cover' 
                                                src={MainCoverImageUrl} 
                                                cropImage={MainCoverImageUrl} 
                                                setCropImage={props.setAllImage}
                                                classRemove={true}
                                            /> 
                                        : ''}
                                    </div>
                                </div>
                                {/* <div className="col-md-12">
                                    <div class="input-uploader">
                                        <label htmlFor="formFile" class="form-label mb-0">Photos:</label>
                                        <button type="button" className="mt-2 w-100 btn btn-light text-primary" id="" data-bs-toggle="modal" data-bs-target="#image_crop_modal" onClick={() => props.setImageName('all_photo')}>
                                            <svg className="icon me-1" role="img"><use href="#icon_plus" /></svg>
                                            Add Photos
                                        </button>
                                        {props.allImage && props.allImage.all_photo ? 
                                            <ShowImage 
                                                name='all_photos' 
                                                src={props.allImage.all_photo.url} 
                                                cropImage={props.allImage} 
                                                setCropImage={props.setAllImage} 
                                            /> 
                                        : ''}
                                    </div>
                                </div> */}
                            </div>
                        </div>
                    </div>    

                    <div className="row mb-3 mt-4">
                        <div className="col-md-4 col-lg-3">
                            <h6 className="mb-0 mt-2 border py-2 px-2 rounded bg-light"><i className="bi bi-google"></i> Google</h6>
                        </div>
                        <div className="col-md-8 col-lg-9">
                            <div className="row g-4">
                                <div className="col-md-6">
                                    <div class="input-uploader">                                        
                                        <button type="button" className="mt-2 w-100 border btn btn-light text-primary" id="" data-bs-toggle="modal" data-bs-target="#image_crop_modal" onClick={() => props.setImageName('google_logo')}>
                                            <svg className="icon me-1" role="img"><use href="#icon_plus" /></svg>
                                            Add Logo
                                        </button>
                                        {props.allImage && GoogleLogoImageUrl ? 
                                            <ShowImage 
                                                name='google_logo' 
                                                src={GoogleLogoImageUrl} 
                                                cropImage={GoogleLogoImageUrl} 
                                                setCropImage={props.setAllImage}
                                                classRemove={true}
                                            /> 
                                        : ''}
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div class="input-uploader">                                        
                                        <button type="button" className="mt-2 w-100 border btn btn-light text-primary" id="" data-bs-toggle="modal" data-bs-target="#image_crop_modal" onClick={() => props.setImageName('google_cover')}>
                                            <svg className="icon me-1" role="img"><use href="#icon_plus" /></svg>
                                            Add Cover
                                        </button>
                                        {props.allImage && GoogleCoverImageUrl ? 
                                            <ShowImage 
                                                name='google_cover' 
                                                src={GoogleCoverImageUrl} 
                                                cropImage={GoogleCoverImageUrl} 
                                                setCropImage={props.setAllImage}
                                                classRemove={true}
                                            /> 
                                        : ''}
                                    </div>
                                </div>
                                {/* <div className="col-md-12">
                                    <div class="input-uploader">
                                        <label htmlFor="formFile" class="form-label mb-0">Photos:</label>
                                        <button type="button" className="mt-2 w-100 btn btn-light text-primary" id="" data-bs-toggle="modal" data-bs-target="#image_crop_modal" onClick={() => props.setImageName('google_photo')}>
                                            <svg className="icon me-1" role="img"><use href="#icon_plus" /></svg>
                                            Add Photos
                                        </button>
                                        {props.allImage && props.allImage.google_photo ? 
                                            <ShowImage 
                                                name='google_photos' 
                                                src={props.allImage.google_photo.url} 
                                                cropImage={props.allImage} 
                                                setCropImage={props.setAllImage} 
                                            /> 
                                        : ''}
                                    </div>
                                </div> */}
                            </div>
                        </div>
                    </div>

                    <div className="row mb-3 mt-4">
                        <div className="col-md-4 col-lg-3">
                            <h6 className="mb-0 mt-2 border py-2 px-2 rounded bg-light"><i className="bi bi-facebook"></i> Facebook</h6>
                        </div>
                        <div className="col-md-8 col-lg-9">
                            <div className="row g-4">
                                <div className="col-md-6">
                                    <div class="input-uploader">                                        
                                        <button type="button" className="mt-2 w-100 border btn btn-light text-primary" id="" data-bs-toggle="modal" data-bs-target="#image_crop_modal" onClick={() => props.setImageName('facebook_logo')}>
                                            <svg className="icon me-1" role="img"><use href="#icon_plus" /></svg>
                                            Add Logo
                                        </button>
                                        {props.allImage && FacebookLogoImageUrl ? 
                                            <ShowImage 
                                                name='facebook_logo' 
                                                src={FacebookLogoImageUrl} 
                                                cropImage={FacebookLogoImageUrl} 
                                                setCropImage={props.setAllImage}
                                                classRemove={true}
                                            /> 
                                        : ''}
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div class="input-uploader">                                        
                                        <button type="button" className="mt-2 border w-100 btn btn-light text-primary" id="" data-bs-toggle="modal" data-bs-target="#image_crop_modal" onClick={() => props.setImageName('facebook_cover')}>
                                            <svg className="icon me-1" role="img"><use href="#icon_plus" /></svg>
                                            Add Cover
                                        </button>
                                        {props.allImage && FacebookCoverImageUrl ? 
                                            <ShowImage 
                                                name='facebook_cover' 
                                                src={FacebookCoverImageUrl} 
                                                cropImage={FacebookCoverImageUrl} 
                                                setCropImage={props.setAllImage}
                                                classRemove={true}
                                            /> 
                                        : ''}
                                    </div>
                                </div>
                            {/*  <div className="col-md-12">
                                    <div class="input-uploader">
                                        <label htmlFor="formFile" class="form-label mb-0">Photos:</label>
                                        <button type="button" className="mt-2 w-100 btn btn-light text-primary" id="" data-bs-toggle="modal" data-bs-target="#image_crop_modal" onClick={() => props.setImageName('facebook_photo')}>
                                            <svg className="icon me-1" role="img"><use href="#icon_plus" /></svg>
                                            Add Photos
                                        </button>
                                        {props.allImage && props.allImage.facebook_photo ? 
                                            <ShowImage 
                                                name='facebook_photos' 
                                                src={props.allImage.facebook_photo.url} 
                                                cropImage={props.allImage} 
                                                setCropImage={props.setAllImage} 
                                            /> 
                                        : ''}
                                    </div>
                                </div> */}
                            </div>
                        </div>
                    </div>               
                </div>                
            </div>

            
        </>
    )
}

export default ImageUploadSoci