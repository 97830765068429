import React, { useEffect, useState } from 'react';

const DayComponent = ({ day, onTimeChange, initialData }) => {
  const [hourType, setHourType] = useState('');
  const [timeSlots, setTimeSlots] = useState([{ from: '00:00', to: '00:00' }]);
  // console.log(initialData, day)


  useEffect(() => {
    if (initialData) {
      setHourType(initialData.hourType || '');
      setTimeSlots(initialData.timeSlots.length > 0 ? initialData.timeSlots : [{ from: '00:00', to: '00:00' }]);
    }
  }, [initialData]);

  const handleHourTypeChange = (e) => {
    const value = e.target.value;
    setHourType(value);

    if (value === 'split') {
      setTimeSlots([{ from: '00:00', to: '00:00' }]);
    } else {
      setTimeSlots([{ from: '00:00', to: '00:00' }]);
      onTimeChange(day, [{ from: '00:00', to: '00:00' }], value);
    }
  };

  const handleTimeChange = (index, field, value) => {
    const newTimeSlots = [...timeSlots];
    newTimeSlots[index][field] = value;
    setTimeSlots(newTimeSlots);
    onTimeChange(day, newTimeSlots, hourType);
  };

  const addTimeSlot = () => {
    setTimeSlots([...timeSlots, { from: '00:00', to: '00:00' }]);
  };

  const removeTimeSlot = (index) => {
    const newTimeSlots = timeSlots.filter((_, i) => i !== index);
    setTimeSlots(newTimeSlots);
    onTimeChange(day, newTimeSlots, hourType);
  };

  return (
    <>
      <style>
        {`
        .not-split-group, .form-group { flex-grow: 1;}
        .dayType-box{width:400px;}
        .dayType-box .form-group:last-child {width:140px;}
        .split-form-group:empty, .not-split-form-group:empty { display: none !important;}
        .split-form-group { flex-grow: 1;}
        .split-form-group .form-group{ flex-grow: 1;}
        @media (max-width:991.98px){
            .dayType-box { width: 100%;}
            .not-split-form-group { width: 100%;}
            .dayType-box .form-group { width: auto; flex: 1;}
        }
      `}
      </style>
    <div className="d-flex flex-column flex-lg-row gap-2 align-items-start">
    <div className='dayType-box d-flex gap-2'>
        <div className='form-group'>
            <input type="text" readOnly className="form-control" name="days[]" value={day} />
        </div>
        <div className='form-group'>
            <select className="form-select" style={{lineHeight:"21px"}} name="business_hourtype[]" value={hourType} onChange={handleHourTypeChange}>
                <option value="">Select</option>
                <option value="open">Open</option>
                <option value="split">Split</option>
                {/* <option value="24">24 Hours</option> */}
                <option value="close">Close</option>
            </select>
        </div>
    </div>
    <div className='split-form-group d-flex  flex-column gap-1 flex-grow-1'>
    {hourType === 'split' && (
        <>
        {timeSlots.map((slot, index) => (
            <>
            <div key={index} className="d-flex gap-1 align-items-center w-100">
                <div className="form-group">
                    <input
                        type="time"
                        className="form-control"
                        name="business_hour_from[]"
                        placeholder="00:00"
                        pattern="([01]?[0-9]{1}|2[0-3]{1}):[0-5]{1}[0-9]{1}"
                        value={slot.from}
                        onChange={(e) => handleTimeChange(index, 'from', e.target.value)}
                    />
                </div>
                <span className="px-1">to</span>
                <div className="form-group">
                    <input
                        type="time"
                        className="form-control"
                        name="business_hour_to[]"
                        placeholder="00:00"
                        pattern="([01]?[0-9]{1}|2[0-3]{1}):[0-5]{1}[0-9]{1}"
                        value={slot.to}
                        onChange={(e) => handleTimeChange(index, 'to', e.target.value)}
                    />
                </div>
                {
                index === 0 ? (
                <button 
                onClick={addTimeSlot}
                type="button"
                className="input-group-text top-0 bottom-0 end-0 rounded-start border"
                style={{ paddingInline: "10px", paddingBlock: '4px' }}
                >
                <i className="bi bi-plus-lg"></i>
                </button>
                ) : (
                    <button
                    onClick={() => removeTimeSlot(index)}
                    type="button"
                    className="input-group-text top-0 bottom-0 end-0 rounded-start border"
                    style={{ paddingInline: "10px", paddingBlock: '4px' }}
                    >
                    <i className="bi bi-trash3"></i>
                    </button>
                )}
            </div>
            
            </>
        ))}
        </>
    )}
    </div>

    {hourType !== 'split' && (
        <div className='not-split-form-group d-flex align-items-center gap-2 flex-grow-1'>
        <input
            type="time"
            className="form-control"
            name="business_hour_from[]"
            placeholder="00:00"
            pattern="([01]?[0-9]{1}|2[0-3]{1}):[0-5]{1}[0-9]{1}"
            value={timeSlots[0].from}
            onChange={(e) => handleTimeChange(0, 'from', e.target.value)}
            readOnly={hourType === 'close' ? true : false}
        />
            <span className="px-1 e">to</span>
        <input
            type="time"
            className="form-control"
            name="business_hour_to[]"
            placeholder="00:00"
            pattern="([01]?[0-9]{1}|2[0-3]{1}):[0-5]{1}[0-9]{1}"
            value={timeSlots[0].to}
            onChange={(e) => handleTimeChange(0, 'to', e.target.value)}
            readOnly={hourType === 'close' ? true : false}
        />
        </div>
    )}
    </div>
    </>
  );
};

export default DayComponent;