import React, { useState, useEffect, useContext } from 'react';
import { Link } from 'react-router-dom';
import { fetchData, GET_URL, ADD_USER_SUBSCRIBE } from './Service'
import { validateForm, initialFormState, zohoChat } from "./Helper"
import { Context } from "./Context"


function FooterFrontNew() {
    const [context] = useContext(Context);
    const user_group_id = context && context.auth && context.auth.user_group_id;
    const opacity = user_group_id === 1 || user_group_id === 3 ? 0 : 1;
    const [blogs, setBlogs] = useState('#');
    const [cases, setCases] = useState('#');
    const [tinyloader, setTinyloader] = useState(false);

    useEffect(() => {
        fetchData(`${GET_URL}?type=blogs`, 'GET', '', true, false, (res) => { if (res.records) { setBlogs(res.records) } })
        fetchData(`${GET_URL}?type=cases`, 'GET', '', true, false, (res) => { if (res.records) { setCases(res.records) } })
    }, [opacity])

    const submitSubscribe = (e) => {
        var business_name = document.querySelector('#business_names').value;
        let formData = new FormData(document.getElementById('subscribeForm'));
        formData.append('business_name', business_name);
        if (validateForm(e, 'subscribeForm')) {
            setTinyloader(true)
            fetchData(ADD_USER_SUBSCRIBE, 'POST', formData, true, true, (res) => {
                setTinyloader(false)
                if (res.success) {
                    initialFormState('subscribeForm')
                }
            })
        }
    }

    const redirectTo = (e, url) => {
        e.preventDefault();
        window.open(url, '_blank');
    }

    return (
        <>
            <style>{`
            .zsiq_theme1.zsiq_floatmain {opacity: ${opacity} !important; pointer-events :all; display:block !important;}
            .siqanim{
                transform: scale(1)!important;
            }
        `}
            </style>
            {user_group_id === '' ? zohoChat() : ''}
            <span className="go-to-top" title="Go to Top"></span>
            <footer className='footer-new'>
                <div className="container pt-5">
                    <div className="row">
                        <div className="col-md-6 col-lg-3">
                            <div className='d-flex flex-column h-100 align-items-start'>
                                <div className='footer-logo mb-3'>
                                    <img src="images/new/logo.svg" alt="footer logo" />
                                </div>   
                                <p>Stay updated with the latest in Digital Marketing and Technology.</p>
                                <div className="mt-2 my-md-5 mb-5">
                                    <Link to="/request-demo" className="new-btn btn-gradiant"> Book Demo <i class="bi bi-arrow-right-short"></i></Link>
                                </div>                            
                                <div className="mt-auto mb-5">
                                    <p className='opacity-75 mb-1'>-Follow on</p>
                                    <ul className="list-inline social-links text-center mb-0 text-md-end">
                                        <li className="list-inline-item">
                                            <a href="https://www.facebook.com/wowamazio/" rel="noopener noreferrer" target="_blank" title="Facebook" className="icon-facebook">
                                                <i className="bi bi-facebook bi-1p2"></i>
                                            </a>
                                            <a href="https://x.com/Amazio_official" rel="noopener noreferrer" target="_blank" title="Twitter" className="icon-twitter" style={{ transform: 'translateY(4px)' }}>
                                                <svg className="bi-1p2 " xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-twitter-x" viewBox="0 0 16 16">
                                                    <path d="M12.6.75h2.454l-5.36 6.142L16 15.25h-4.937l-3.867-5.07-4.425 5.07H.316l5.733-6.57L0 .75h5.063l3.495 4.633L12.601.75Zm-.86 13.028h1.36L4.323 2.145H2.865z" />
                                                </svg>
                                            </a>
                                            <a href="https://www.linkedin.com/company/amazio-us/" rel="noopener noreferrer" target="_blank" title="Linkedin" className="icon-linkedin">
                                                <i className="bi bi-linkedin bi-1p2"></i>
                                            </a>
                                            <a href="https://www.instagram.com/amazio_official/" rel="noopener noreferrer" target="_blank" title="Instagram" className="icon-instagram">
                                                <i className="bi bi-instagram bi-1p2"></i>
                                            </a>
                                            <a href="https://in.pinterest.com/Amazio_Official/" rel="noopener noreferrer" target="_blank" title="Pinterest" className="icon-pinterest">
                                                <i className="bi bi-pinterest bi-1p2"></i>
                                            </a>
                                            <a href="https://www.google.com/maps/place/Amazio/@32.8855327,-96.9709239,17z/data=!3m1!4b1!4m5!3m4!1s0x0:0xf48e09944b270eb2!8m2!3d32.8855327!4d-96.9687352" rel="noopener noreferrer" target="_blank" title="Google" className="icon-google">
                                                <i className="bi bi-google bi-1p1"></i>
                                            </a>
                                            <a href="https://www.snapchat.com/add/amazio_official" rel="noopener noreferrer" target="_blank" title="Google" className="icon-snapchat">
                                                <i className="bi bi-snapchat bi-1p1"></i>
                                            </a>
                                            <a href="https://www.quora.com/profile/Amazio-Information" rel="noopener noreferrer" target="_blank" title="Quora" className="icon-quora">
                                                <i className="bi bi-quora bi-1p1"></i>
                                            </a>
                                            <a href="https://www.reddit.com/user/TangerineSame4421/" rel="noopener noreferrer" target="_blank" title="Reddit" className="icon-reddit">
                                                <i className="bi bi-reddit bi-1p1"></i>
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6 col-lg-2">
                            <h5 className='footer-heading'><span>SITEMAP </span></h5>
                            <ul className="footer-links">
                                <li><Link to="/"> <i class="bi bi-chevron-double-right"></i> Home</Link></li>
                                <li><Link to="services"> <i class="bi bi-chevron-double-right"></i> Services</Link></li>
                                <li><Link to="industries"> <i class="bi bi-chevron-double-right"></i> Industries</Link></li>
                                <li><Link to="plans-pricing"> <i class="bi bi-chevron-double-right"></i> Plans &amp; Pricing</Link></li>
                                <li><Link to="knowledge-base"> <i class="bi bi-chevron-double-right"></i> Knowledge Base</Link></li>
                                <li><Link to="about-us"> <i class="bi bi-chevron-double-right"></i> About Us</Link></li>
                                <li><a href='/blogs' target="_blank" rel="noreferrer" onClick={(e) => redirectTo(e, blogs)}><i class="bi bi-chevron-double-right"></i> Blogs</a></li>
                                <li><a href='case-study' target="_blank" rel="noreferrer" onClick={(e) => redirectTo(e, cases)}><i class="bi bi-chevron-double-right"></i>Case Studies</a></li>
                                <li><Link to="onboarding-form"> <i class="bi bi-chevron-double-right"></i> Onboarding Form</Link></li>
                                <li><Link to="scan-your-business"> <i class="bi bi-chevron-double-right"></i> Scan Your Business</Link></li>
                                <li><Link to="contact-us"> <i class="bi bi-chevron-double-right"></i> Contact Us</Link></li>
                                <li><a href='/sitemap.html' target="_blank" rel="noreferrer"><i class="bi bi-chevron-double-right"></i> Site Map</a></li>
                            </ul>
                        </div>
                        <div className="col-md-6 col-lg-4">
                            <h5 className='footer-heading'><span>SERVICES </span></h5>
                            <ul className="footer-links">
                                <li><Link to="services"> <i class="bi bi-chevron-double-right"></i> All Services</Link></li>
                                <li><Link to="/business-listing-management-usa"> <i class="bi bi-chevron-double-right"></i> Business Digital Listing &amp; Branding</Link></li>
                                <li><Link to="social-media-management"> <i class="bi bi-chevron-double-right"></i> Social Media Management</Link></li>
                                <li><Link to="integrated-digital-marketing"> <i class="bi bi-chevron-double-right"></i>Integrated Digital Marketing</Link></li>
                                <li><Link to="search-engine-optimization"> <i class="bi bi-chevron-double-right"></i> Search Engine Optimization (SEO)</Link></li>
                                <li><Link to="pay-per-click"> <i class="bi bi-chevron-double-right"></i> Pay Per Click (PPC)</Link></li>
                                <li><Link to="reviews-reputation-sentiment-analysis"> <i class="bi bi-chevron-double-right"></i> Reviews, Reputation &amp; Sentiment Analysis</Link></li>
                                <li><Link to="branding-design-services"> <i class="bi bi-chevron-double-right"></i> Branding &amp; Design services</Link></li>
                                <li><Link to="competitive-intelligence-insights-analytics"> <i class="bi bi-chevron-double-right"></i> Competitive Intelligence, Insights &amp; Analytics</Link></li>
                                {/* <li><Link to="web-mobile-informational-tags"> <i class="bi bi-chevron-double-right"></i> Web &amp; Mobile Informational Tags</Link></li> */}
                                <li><Link to="web-app-development-maintenance"> <i class="bi bi-chevron-double-right"></i> Web &amp; App Development with Maintenance</Link></li>
                                {/* <li><Link to="voice-search-pro"> <i class="bi bi-chevron-double-right"></i> Voice Search Pro</Link></li> */}
                                <li><Link to="print-media"> <i class="bi bi-chevron-double-right"></i> Print Media</Link></li>
                                <li><Link to="digital-content-services"> <i class="bi bi-chevron-double-right"></i> Digital Content Services</Link></li>
                            </ul>
                        </div>
                        <div className="col-md-6 col-lg-3">
                            <h5 className='footer-heading'><span> GET IN TOUCH </span></h5>
                            <div className='footer-links mt-4'>
                                <div className='footer-conact-item'>
                                    <i class="bi bi-geo-alt-fill"></i>
                                    <address className='mb-0'>
                                        1303 W Walnut Hill Ln, Ste 360 Irving TX 75038. USA
                                    </address>
                                </div>
                                <div className='footer-conact-item'>
                                    <i class="bi bi-telephone-fill"></i>
                                    <a href="mailto:info@amazio.com">
                                        info@amazio.com
                                    </a>
                                </div>
                                <div className='footer-conact-item'>
                                    <i class="bi bi-envelope-fill"></i>
                                    <a href="tel:9723624700">
                                        972-362-4700
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="copyrights py-4">
                    <div className="container">
                        <div className="row g-3">
                            <div className="col-lg-6 text-center text-lg-start">
                                <p><i className="bi bi-c-circle"></i> {new Date().getFullYear()} AMAZIO. All rights reserved.</p>
                            </div>
                            <div className="col-lg-6">
                                <div className='footer-nav d-flex flex-wrap gap-3 justify-content-center justify-content-lg-end'>
                                    <Link to='terms-conditions' className='footer-nav-link'>Terms & Conditions</Link>
                                    <Link to='privacy-policy' className='footer-nav-link'>Privacy Policy</Link>
                                    <Link to='cookie-policy' className='footer-nav-link'>Cookie Policy</Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </footer>
        </>
    )
}

export default FooterFrontNew
