import React from "react";
import { useNavigate } from "react-router-dom";
import HeaderFrontNew from "../../components/HeaderFrontNew";

function Error301() {
    const navigate = useNavigate();
    return (<>
        <HeaderFrontNew />
        <div className="w-100 min-vh-100 d-flex justify-content-center align-items-center">
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-sm-12 text-center">
                        <h1 className="display-1 fw-bold text-warning">301</h1>
                        <h2>Page Moved Permanently</h2>
                        <hr className="maxw-180 mx-auto" />
                        <div className="fs-4 py-3 mb-3 text-secondary">
                            <div>The requested page has been permanently moved.</div>
                            <div>You will be redirected to the home page shortly...</div>
                        </div>
                        <button type="button" className="btn btn-lg btn-dark px-4 fw-medium" onClick={() => navigate("/", { replace: true })}>
                            Go to Home Now
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </>)
}
export default Error301;
