import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter as Router } from "react-router-dom";
import "bootstrap";
import './index.scss';
import './css/custom.css';
import './css/style.scss'

import Routing from './components/Routing';
import Icons from './components/Icons';
import reportWebVitals from './reportWebVitals';
import "react-datepicker/dist/react-datepicker.css";
import 'intl-tel-input/build/css/intlTelInput.css';
import 'react-loading-skeleton/dist/skeleton.css';
import $ from "jquery";
window.jQuery = $;
require('owl.carousel/dist/assets/owl.carousel.min.css');
require('owl.carousel');

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <React.StrictMode>
        <Router >
            <Routing />
        </Router>
        <Icons />
    </React.StrictMode>
);

reportWebVitals();
