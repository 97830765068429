import React, { useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const SpecialHours = ({ specialHours, setSpecialHours }) => {
    const [showDatePicker, setShowDatePicker] = useState(false);
    const [selectedRange, setSelectedRange] = useState([null, null]);

    const addSpecialHour = () => {
        setShowDatePicker(prev => !prev);
    };

    // const handleDateSelect = (dates) => {
    //     const [start, end] = dates;
    //     if (start && end) {
    //         const startDay = start.getDate(); // Extracts day of the month (1-31)
    //         const endDay = end.getDate(); 
    //         const startDate = start.toISOString().split("T")[0];
    //         const endDate = end.toISOString().split("T")[0];
    //         const timeDiff = end.getTime() - start.getTime();
    //         const daysCount = Math.ceil(timeDiff / (1000 * 60 * 60 * 24)) + 1;
    //         const formattedRange = `${daysCount} days (${startDay} to ${endDay})`;

    //         setSpecialHours((prevHours) => [
    //             ...prevHours,
    //             {
    //                 id: Date.now(),
    //                 range: formattedRange,
    //                 start_date: startDate,
    //                 end_date: endDate,
    //                 timeSlots: [{ startTime: "", endTime: "" }],
    //                 isOpen: true,
    //             },
    //         ]);
    //         setShowDatePicker(false);
    //         setSelectedRange([null, null]);
    //     } else {
    //         setSelectedRange(dates);
    //     }
    // };

    const handleDateSelect = (date) => {
        if (date) {
            const selectedDay = date.getDate();
            const selectedMonth = date.toLocaleString("default", { month: "long" }); 
            const selectedYear = date.getFullYear();
            const formattedDate = `${selectedMonth} ${selectedDay}, ${selectedYear}`; 
    
            const selectedDate = date.toISOString().split("T")[0]; 
    
            setSpecialHours((prevHours) => [
                ...prevHours,
                {
                    id: Date.now(),
                    range: `(${formattedDate})`,
                    start_date: selectedDate,
                    end_date: selectedDate,
                    timeSlots: [{ startTime: "", endTime: "" }],
                    isOpen: true,
                },
            ]);
            setShowDatePicker(false);
            setSelectedRange([date, date]);
        }
    };
    const removeSpecialHour = (id) => {
        if (!specialHours) return;
        setSpecialHours(specialHours.filter((hour) => hour.id !== id));
    };
    
    const updateSpecialHour = (id, field, value) => {
        if (!specialHours) return;
        setSpecialHours(
            specialHours.map((hour) =>
                hour.id === id ? { ...hour, [field]: value } : hour
            )
        );
    };
    
    const addTimeSlot = (id) => {
        if (!specialHours) return;
        setSpecialHours(
            specialHours.map((hour) =>
                hour.id === id
                    ? {
                          ...hour,
                          timeSlots: hour.timeSlots ? [...hour.timeSlots, { startTime: "", endTime: "" }] : [{ startTime: "", endTime: "" }]
                      }
                    : hour
            )
        );
    };
    
    const removeTimeSlot = (hourId, index) => {
        if (!specialHours) return;
        setSpecialHours(
            specialHours.map((hour) =>
                hour.id === hourId && Array.isArray(hour.timeSlots)
                    ? { ...hour, timeSlots: hour.timeSlots.filter((_, i) => i !== index) }
                    : hour
            )
        );
    };

    return (
        <div className="form-group">
            <button type="button" className="btn btn-primary mb-3" onClick={addSpecialHour}>
                <svg className="icon tscale-1p3 me-1" role="img">
                    <use href="#icon_plus" />
                </svg>
                Add Special Hours
            </button>
            <div className="mb-0">
                {showDatePicker && (
                    <DatePicker
                        selected={selectedRange[0]}
                        onChange={handleDateSelect}
                        startDate={selectedRange[0]}
                        endDate={selectedRange[1]}
                        selectsRange={false}
                        inline
                        minDate={new Date()}
                    />
                )}
            </div>
            {Array.isArray(specialHours) && specialHours.map(({ id, range, start_date, end_date, timeSlots, isOpen }) => (
                <div key={id} className="card mb-2 p-3 shadow-sm">
                    <div className="d-flex justify-content-between align-items-center">
                        <div>
                            <strong>{range}</strong>
                        </div>
                        <button className="btn btn-outline-danger btn-sm" onClick={() => removeSpecialHour(id)}>
                            <svg className="icon tscale-1p2 opacity-75" role="img">
                                <use href="#icon_trash" />
                            </svg>
                        </button>
                    </div>
                   
                    <div className="d-flex justify-content-end align-items-center">
                        <button type="button" className="btn btn-outline-danger btn-sm mt-2" onClick={() => addTimeSlot(id)}>
                        <svg className="icon tscale-1p2 opacity-75" role="img">
                                <use href="#icon_plus" />
                            </svg> Add More Time
                        </button>
                    </div>

                    <div className="d-flex align-items-center mt-2">
                        <label className="switch me-2">
                            <input type="checkbox" checked={isOpen} onChange={() => updateSpecialHour(id, "isOpen", !isOpen)} />
                            <span className="slider round"></span>
                        </label>
                        <span>Open</span>
                    </div>
                    
                    {timeSlots.map((slot, index) => (
                        <div key={index} className="d-flex align-items-center mt-2">
                            <select className="form-select mx-2 w-auto" value={slot.startTime} onChange={(e) => {
                                const newTimeSlots = [...timeSlots];
                                newTimeSlots[index].startTime = e.target.value;
                                updateSpecialHour(id, "timeSlots", newTimeSlots);
                            }}>
                                <option value="">--:--</option>
                                <option value="24 Hours">24 Hours</option>
                                {Array.from({ length: 24 }).map((_, hour) =>
                                    [0, 30].map((minute) => {
                                        const value = `${String(hour).padStart(2, "0")}:${String(minute).padStart(2, "0")}`;
                                        const period = hour < 12 ? "AM" : "PM";
                                        const displayHour = hour === 0 ? 12 : hour > 12 ? hour - 12 : hour;
                                        return (
                                            <option key={value} value={value}>{`${displayHour}:${String(minute).padStart(2, "0")} ${period}`}</option>
                                        );
                                    })
                                )}
                            </select>
                            <span>to</span>
                            <select className="form-select mx-2 w-auto" value={slot.endTime} onChange={(e) => {
                                const newTimeSlots = [...timeSlots];
                                newTimeSlots[index].endTime = e.target.value;
                                updateSpecialHour(id, "timeSlots", newTimeSlots);
                            }}>
                                <option value="">--:--</option>
                                <option value="24 Hours">24 Hours</option>
                                {Array.from({ length: 24 }).map((_, hour) =>
                                    [0, 30].map((minute) => {
                                        const value = `${String(hour).padStart(2, "0")}:${String(minute).padStart(2, "0")}`;
                                        const period = hour < 12 ? "AM" : "PM";
                                        const displayHour = hour === 0 ? 12 : hour > 12 ? hour - 12 : hour;
                                        return (
                                            <option key={value} value={value}>{`${displayHour}:${String(minute).padStart(2, "0")} ${period}`}</option>
                                        );
                                    })
                                )}
                            </select>
                            <button type="button" className="btn btn-outline-danger btn-sm ms-2" onClick={() => removeTimeSlot(id, index)}>Remove</button>
                        </div>
                    ))}
                </div>
            ))}
        </div>
    );
};

export default SpecialHours;
