import React, { useState } from "react";
import TagsInput from "react-tagsinput";
import 'react-tagsinput/react-tagsinput.css'

const TagInputComponent = ({ name, value, setValue, error, parentClassName = "",placeholder, disabled = false }) => {
    // const [tags, setTags] = useState(value);
    const handleInputChange = (data) => {
        // setTags(data);
        setValue(data);
    };

    return (
        <>  
            <style>{`
            .input-tags-container {
                .input-tags-group {
                    padding: 0.375rem 0.75rem;
                    border: 1px solid #dee2e6;
                    border-radius: 5px;
                    > span {
                        display: flex;
                        flex-wrap: wrap;
                        align-items: center;
                        gap: 8px;
                        width: 100%;
                        input {
                            margin: 0 !important;
                            padding: 0;
                            line-height: 22px;
                            border: none !important;
                            outline: none !important;
                            box-shadow: none !important;
                            flex-grow: 1;
                            width: auto;
                        }
                    }
                    .react-tagsinput-tag {
                        margin: 0 !important;
                        font-size: 14px;
                        white-space: normal;
                        word-break: break-all;
                        font-weight: 500;
                        padding: 4px 10px;
                        line-height: 14px;
                        border-radius: 14px;
                        border: none !important;
                        a{
                            &::before {
                                text-align: center;
                                padding-left: 4px;
                                font-family: 'Inter';
                                font-size: 14px;
                                font-weight: 600;
                                display: inline-block;
                            }
                        }

                        &:nth-child(7n + 1) {
                            background-color: #EEF4FF;
                            color: #3538CD;
                        }

                        &:nth-child(7n + 2) {
                            background-color: #F4F3FF;
                            color: #5925DC;
                        }

                        &:nth-child(7n + 3) {
                            background-color: #F0F9FF;
                            color: #026AA2;
                        }

                        &:nth-child(7n + 4) {
                            background-color: #10B9811A;
                            color: #047857;
                        }

                        &:nth-child(7n + 5) {
                            background-color: #F59E0B1A;
                            color: #B45309;
                        }

                        &:nth-child(7n + 6) {
                            background-color: #FFE4E6;
                            color: #BE123C;
                        }

                        &:nth-child(7n + 7) {
                            background-color: #F8F9FC;
                            color: #363F72;
                        }
                    }
                }

            }`}</style>
            <div className={`input-tags-container ${parentClassName ?? ''}`}>
                <TagsInput disabled={disabled} className={`input-tags-group ${error ? 'error' :  'input-tag'}`} inputProps={{ name: name, placeholder: placeholder ? placeholder : 'Add a Tag' }} value={value} onChange={handleInputChange} />
                <p className="text-error">{error?.message}</p>
            </div>
        </>
    );
}

export default TagInputComponent