import React, { useEffect, useContext } from 'react';
import { Link, NavLink, useLocation, useNavigate } from 'react-router-dom';
import { Context } from '../components/Context'
import { fetchData, LOGOUT } from '../components/Service'
import MetaComponent from './MetaComponent';

function HeaderFrontNew() {
    const useloc = useLocation();
    const [context, setContext] = useContext(Context)
    const user_group_id = context && context.auth && context.auth.user_group_id;
    const navigate = useNavigate();
    useEffect(() => {
        window.scroll({ top: 0, left: 0, behavior: 'auto' });
    }, [useloc.pathname])

    const customerManaged = (e, type) => {
    }
    const handleLogout = () => {
        fetchData(LOGOUT, 'POST', '', true, true, (res) => {
            if (res.success) {
                localStorage.removeItem('amazio-token')
                setContext('')
                navigate(`/log-in`);
            }
        });
    }

    return (

        <nav className="new-header navbar navbar-expand-xl">
            <MetaComponent />
            <div className="container">
                <Link to="/" className="navbar-brand me-lg-4">
                    <img src="images/new/logo-header.png" alt="Amazio Logo" />
                </Link>
                <button className="navbar-toggler px-2 collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapsibleNavbar">
                    <span className="navbar-toggler-icon"></span>
                </button>
                <div className="collapse navbar-collapse" id="collapsibleNavbar">
                    <ul className="navbar-nav align-items-xl-center">
                        {!user_group_id &&
                            <li className="nav-item dropdown has-mega-menu">
                                <Link className="nav-link dropdown-toggle" to="#" role="button" data-bs-toggle="dropdown">SERVICES</Link>
                                <div className="dropdown-menu shadow-lg border-0">
                                    <ul className='has-mega-list'>
                                        <li>
                                            <Link className="has-mega-link" to="/business-listing-management-usa">
                                                <span>
                                                    <img src="images/new/mega-1.png" alt="" />
                                                </span>
                                                <h4>Business digital Listing & Branding </h4>
                                                <p>Empowering businesses with digital listings and innovative branding solutions.</p>
                                                <i class="bi bi-chevron-right d-md-none"></i>
                                            </Link>
                                        </li>
                                        <li>
                                            <Link className="has-mega-link" to="/social-media-management">
                                                <span>
                                                    <img src="images/new/mega-2.png" alt="" />
                                                </span>
                                                <h4>Social Media Management</h4>
                                                <p>Analyzing reviews to assess reputation and uncover sentiment trends effectively.</p>
                                                <i class="bi bi-chevron-right d-md-none"></i>
                                            </Link>
                                        </li>
                                        <li>
                                            <Link className="has-mega-link" to="/integrated-digital-marketing">
                                                <span>
                                                    <img src="images/new/mega-10.png" alt="" />
                                                </span>
                                                <h4>Integrated Digital Marketing</h4>
                                                <p>Unlock growth with Amazio’s integrated digital marketing services – where strategy, creativity, and technology converge to drive impactful results.</p>
                                                <i class="bi bi-chevron-right d-md-none"></i>
                                            </Link>
                                        </li>
                                        <li>
                                            <Link className="has-mega-link" to="/search-engine-optimization">
                                                <span>
                                                    <img src="images/new/seo.png" alt="" />
                                                </span>
                                                <h4>Search Engine Optimization (SEO)</h4>
                                                <ul className='mega-menu-list-inn'>
                                                    <li>
                                                        <a href="#" className='mega-menu-list-link'>Website Audit and Analysis</a>
                                                    </li>
                                                    <li>
                                                        <a href="#" className='mega-menu-list-link'>Keyword Research and Strategy</a>
                                                    </li>
                                                    <li>
                                                        <a href="#" className='mega-menu-list-link'>On-Page SEO Optimization</a>
                                                    </li>
                                                    <li>
                                                        <a href="#" className='mega-menu-list-link'>Content Creation and Optimization</a>
                                                    </li>
                                                </ul>
                                                <i class="bi bi-chevron-right d-md-none"></i>
                                            </Link>
                                        </li>
                                        <li>
                                            <Link className="has-mega-link" to="/pay-per-click">
                                                <span>
                                                    <img src="images/new/ppc.png" alt="" />
                                                </span>
                                                <h4>Pay Per Click (PPC)</h4>
                                                <ul className='mega-menu-list-inn'>
                                                    <li>
                                                        <a href="#" className='mega-menu-list-link'>Campaign Strategy Development</a>
                                                    </li>
                                                    <li>
                                                        <a href="#" className='mega-menu-list-link'>Keyword Research &amp; Optimization</a>
                                                    </li>
                                                    <li>
                                                        <a href="#" className='mega-menu-list-link'>Ad Copywriting &amp; Creative Design</a>
                                                    </li>
                                                    <li>
                                                        <a href="#" className='mega-menu-list-link'>Landing Page Optimization</a>
                                                    </li>
                                                </ul>
                                                <i class="bi bi-chevron-right d-md-none"></i>
                                            </Link>
                                        </li>
                                        <li>
                                            <Link className="has-mega-link" to="/reviews-reputation-sentiment-analysis">
                                                <span>
                                                    <img src="images/new/mega-3.png" alt="" />
                                                </span>
                                                <h4>Reviews, Reputation & Sentiment Analysis</h4>
                                                <p>Empowering businesses with digital listings and innovative branding solutions.</p>
                                                <i class="bi bi-chevron-right d-md-none"></i>
                                            </Link>
                                        </li>
                                        <li>
                                            <Link className="has-mega-link" to="/branding-design-services">
                                                <span>
                                                    <img src="images/new/mega-4.png" alt="" />
                                                </span>
                                                <h4>Branding & Design services</h4>
                                                <p>Creative digital content and imaging solutions for impactful brand storytelling.</p>
                                                <i class="bi bi-chevron-right d-md-none"></i>
                                            </Link>
                                        </li>
                                        <li>
                                            <Link className="has-mega-link" to="/competitive-intelligence-insights-analytics">
                                                <span>
                                                    <img src="images/new/mega-5.png" alt="" />
                                                </span>
                                                <h4>Competitive Intelligence, Insights & Analytics</h4>
                                                <p>Data-driven strategies leveraging analytics for competitive insights and intelligence.</p>
                                                <i class="bi bi-chevron-right d-md-none"></i>
                                            </Link>
                                        </li>
                                        {/* <li>
                                            <Link className="has-mega-link" to="/web-mobile-informational-tags">
                                                <span>
                                                    <img src="images/new/mega-6.png" alt="" />
                                                </span>
                                                <h4>Web & Mobile Informational Tags</h4>
                                                <p>Interactive tags enhancing user navigation on web and mobile platforms.</p>
                                                <i class="bi bi-chevron-right d-md-none"></i>
                                            </Link>
                                        </li> */}
                                        <li>
                                            <Link className="has-mega-link" to="/web-app-development-maintenance">
                                                <span>
                                                    <img src="images/new/mega-7.png" alt="" />
                                                </span>
                                                <h4>Web & App Development With Maintenance</h4>
                                                <p>Comprehensive web and app solutions with ongoing support and maintenance.</p>
                                                <i class="bi bi-chevron-right d-md-none"></i>
                                            </Link>
                                        </li>
                                        {/* <li>
                                            <Link className="has-mega-link" to="/voice-search-pro">
                                                <span>
                                                    <img src="images/new/mega-8.png" alt="" />
                                                </span>
                                                <h4>Voice search Pro</h4>
                                                <p>Effortless voice-activated search for instant results and convenience.</p>
                                                <i class="bi bi-chevron-right d-md-none"></i>
                                            </Link>
                                        </li> */}
                                        <li>
                                            <Link className="has-mega-link" to="/print-media">
                                                <span>
                                                    <img src="images/new/mega-9.png" alt="" />
                                                </span>
                                                <h4>Print Media</h4>
                                                <p>Traditional mass communication through newspapers, magazines, brochures, and physical publications.</p>
                                                <i class="bi bi-chevron-right d-md-none"></i>
                                            </Link>
                                        </li>
                                        <li>
                                            <Link className="has-mega-link" to="/digital-content-services">
                                                <span>
                                                    <img src="images/new/dcs.png" alt="" />
                                                </span>
                                                <h4>Digital Content Services</h4>
                                                <p>Transforming Ideas into Impactful Messages.</p>
                                                <i class="bi bi-chevron-right d-md-none"></i>
                                            </Link>
                                        </li>
                                    </ul>
                                </div>
                            </li>
                        }
                        <li className="nav-item">
                            <NavLink className="nav-link" to="industries">INDUSTRIES</NavLink>
                        </li>
                        <li className="nav-item">
                            <NavLink className="nav-link" to="plans-pricing">PLANS & PRICING</NavLink>
                        </li>
                        <li className="nav-item">
                            <NavLink className="nav-link" to="knowledge-base">KNOWLEDGE BASE</NavLink>
                        </li>
                        {/* <li className="nav-item" ><Link  className="nav-link"  to="#" target="_blank" rel="noreferrer">BLOGS</Link></li> */}
                        <li className="nav-item" ><a  className="nav-link"  href="https://blog.amazio.com/" target="_blank" rel="noreferrer">BLOGS</a></li>
                    </ul>

                    {context && context.auth ? <div className="footer-action-wrapper px-3  mt-auto mt-xl-0 ms-xl-auto">
                        <ul className="navbar-nav align-items-center">
                            <li className="nav-item dropdown">
                                <button type="button" className="dropdown-toggle new-btn btn-gradiant px-3" data-bs-toggle="dropdown">
                                    <svg className="icon tscale-1p2 opacity-75 me-3" role="img">
                                        <use href="#icon_user" />
                                    </svg>
                                    {context && context.auth && <span>{context.auth.first_name} {context.auth.last_name}</span>}
                                </button>
                                <ul className="dropdown-menu dropdown-menu-end shadow-lg border-0 mt-11">
                                    {context && context.auth && context.auth.email && <li>
                                        <a href={`mailto:${context.auth.email}`} title="Email" className="dropdown-item py-2">
                                            <svg className="icon tscale-1p2 opacity-75 me-3" role="img">
                                                <use href="#icon_envalop" />
                                            </svg>
                                            <span>{context.auth.email}</span>
                                        </a>
                                    </li>}
                                    {context && context.auth && context.auth.phone && <li>
                                        <a href={`tel:${context.auth.phone}`} title="Call" className="dropdown-item py-2">
                                            <svg className="icon tscale-1p2 opacity-75 me-3" role="img">
                                                <use href="#icon_whatsapp" />
                                            </svg>
                                            <span>{context.auth.phone}</span>
                                        </a>
                                    </li>}
                                    <hr />
                                    <li>
                                        <Link to={context.auth.user_group_id === 1 ? 'admin/admin-dashboard' : 'users/select-location'} title="Dashboard" className="dropdown-item py-2">
                                            <svg className="icon tscale-1p1 opacity-75 me-3" role="img">
                                                <use href="#icon_dashboard" />
                                            </svg>
                                            Dashboard
                                        </Link>
                                    </li>
                                    <li>
                                        <button type="button" className="dropdown-item py-2" onClick={handleLogout} title="Logout">
                                            <svg className="icon tscale-1p2 opacity-75 me-3" role="img">
                                                <use href="#icon_logout" />
                                            </svg>
                                            Logout
                                        </button>
                                    </li>
                                </ul>
                            </li>
                        </ul>
                    </div>
                        :
                        <div className="footer-action-wrapper  mt-auto mt-xl-0 ms-xl-auto">
                            <ul className="navbar-nav align-items-xl-center">
                                <li className="nav-item">
                                    <NavLink className="nav-link header-login-btn" to="log-in"> <i class="bi bi-person fs-5 me-1"></i> Login </NavLink>
                                </li>
                                <li className="nav-item d-flex flex-column gap-2 flex-xl-row ms-xl-3">
                                    <Link to="/sign-up" className="new-btn btn-green" onClick={(e) => customerManaged(e, 'signup_customer')}>Sign Up</Link>
                                    <hr className='d-xl-none my-1' />
                                    <Link to="request-demo" className='new-btn btn-gradiant m-0'>
                                        Request Demo
                                    </Link>
                                </li>
                            </ul>
                        </div>
                    }
                </div>
            </div>
        </nav>
    )
}

export default HeaderFrontNew
