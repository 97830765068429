import React, { useState, useEffect, useLayoutEffect, Suspense, lazy } from 'react';
import { Routes, Route, useNavigate, useLocation, redirect } from "react-router-dom";
import { Context } from './Context';
import { LayoutOfFront, LayoutOfAsideAndHeader, PageLoader } from './LayoutOfAsideAndHeader';

/*Start | Import for Fron Pages*/
import Home from '../modules/Front/Home';
// import DigitalContent from '../modules/Front/DigitalContent';
// import KnowledgeTags from '../modules/Front/KnowledgeTags';
// import VoiceSearchPro from '../modules/Front/VoiceSearchPro';
// import ManageServices from '../modules/Front/ManageServices';
/*End | Import for Front Pages*/

import SociManageBusinessListing from '../modules/ManageBusinessListing/SociManageBusinessListing';
import ErrorPage from './ErrorPage';
import { fetchData, GET_AUTH_USER_INFO } from './Service';
import ProtectedRoute from './ProtectedRoute';
import Error404 from '../modules/Errors/404';
import Error301 from '../modules/Errors/301';
import Error302 from '../modules/Errors/302';


const SociSegmentAnalysis = lazy(() => import('../modules/ReputationMangement/SociSentimentAnalysis'));
const SociViewLocation = lazy(() => import('../modules/ManageBusinessListing/SociViewLocation'));
const SociDashboardAdmin = lazy(() => import('../modules/DashboardAdmin/SociDashboardAdmin'));
const SociSocialInsight = lazy(() => import('../modules/DashboardAdmin/SociSocialInsight'));
const SociCompetitiveAnalysis = lazy(() => import('../modules/ReputationMangement/SociCompetitiveAnalysis'));
const SociReviewResponse = lazy(() => import('../modules/ReputationMangement/SociReviewResponse'));


/*Start | Import for Fron Pages*/
const AllServices = lazy(() => import('../modules/Front/AllServices'));
const Industries = lazy(() => import('../modules/Front/Industries/Industries'));
const Pricing = lazy(() => import('../modules/Front/Pricing'));
const Digital = lazy(() => import('../modules/Front/Digital'));
const KnowledgeBase = lazy(() => import('../modules/Front/KnowledgeBase'));
const Websites = lazy(() => import('../modules/Front/Websites'));
const CompetitiveAnalytics = lazy(() => import('../modules/Front/CompetitiveAnalytics'));
const PrintMedia = lazy(() => import('../modules/Front/PrintMedia'));
const CookiePolicy = lazy(() => import('../modules/Front/CookiePolicy'));
const PrivacyPolicy = lazy(() => import('../modules/Front/PrivacyPolicy'));
const TermsAndConditions = lazy(() => import('../modules/Front/TermsAndConditions'));
const ContactUs = lazy(() => import('../modules/Front/ContactUs'));
const AboutUs = lazy(() => import('../modules/Front/AboutUs'));
const Reputation = lazy(() => import('../modules/Front/Reputation'));
const OnboardingForm = lazy(() => import('../modules/Front/OnboardingForm'));
const Hospitality = lazy(() => import('../modules/Front/Industries/Hospitality'));
const Retail = lazy(() => import('../modules/Front/Industries/Retail'));
const Food = lazy(() => import('../modules/Front/Industries/Food'));
const Healthcare = lazy(() => import('../modules/Front/Industries/Healthcare'));
const Insurance = lazy(() => import('../modules/Front/Industries/Insurance'));
const Government = lazy(() => import('../modules/Front/Industries/Government'));
const Franchises = lazy(() => import('../modules/Front/Industries/Franchises'));
const Automotive = lazy(() => import('../modules/Front/Industries/Automotive'));
const SmallBusiness = lazy(() => import('../modules/Front/Industries/SmallBusiness'));
const FacebookSetup = lazy(() => import('../modules/Front/FacebookSetup'));
const GoogleBusinessVerification = lazy(() => import('../modules/Front/GoogleBusinessVerification'));
const Resources = lazy(() => import('../modules/Front/Resources'));
/*End | Import for Front Pages*/

const Login = lazy(() => import('../modules/Auth/Login'));
const ResetPassword = lazy(() => import('../modules/Auth/ResetPassword'));
const Dashboard = lazy(() => import('../modules/Dashboard/Dashboard'));
const ManageUsers = lazy(() => import('../modules/ManageUsers/ManageUsers'));
const ManageBusinessListing = lazy(() => import('../modules/ManageBusinessListing/ManageBusinessListing'));
const DashboardAdmin = lazy(() => import('../modules/DashboardAdmin/DashboardAdmin'));
const SalesAgent = lazy(() => import('../modules/SalesAgent/SalesAgent'));
const Support = lazy(() => import('../modules/Support/Support'));
const ManagePlan = lazy(() => import('../modules/Accounting/ManagePlan'));
const BookKeeping = lazy(() => import('../modules/Accounting/BookKeeping'));
const PromoCode = lazy(() => import('../modules/Accounting/PromoCode'));
const RateUs = lazy(() => import('../modules/Support/RateUs'));
const DemoRequest = lazy(() => import('../modules/Support/DemoRequest'));
const WebsiteSupport = lazy(() => import('../modules/Support/WebsiteSupport'));
const ManageFaq = lazy(() => import('../modules/Support/ManageFaq'));
const RequestCallBack = lazy(() => import('../modules/Support/RequestCallBack'));
const AddFaq = lazy(() => import('../modules/Support/AddFaq'));
const ManageSubscriber = lazy(() => import('../modules/ManageSubscriber/ManageSubscriber'));
const UserActivity = lazy(() => import('../modules/UserActivity/UserActivity'));
const ActivityLog = lazy(() => import('../modules/UserActivity/ActivityLog'));
const PaymentInvoice = lazy(() => import('../modules/Accounting/PaymentInvoice'));
const Onboarding = lazy(() => import('../modules/SocialMediaManager/Onboarding'));
const ChangePassword = lazy(() => import('../modules/Auth/ChangePassword'));
const MyProfile = lazy(() => import('../modules/Auth/MyProfile'));
const Report = lazy(() => import('../modules/SocialMediaManager/Reports'));
const SocialMedia = lazy(() => import('../modules/Front/SocialMedia'));
const CompetitiveAnalysis = lazy(() => import('../modules/ReputationMangement/CompetitiveAnalysis'));
const ReviewResponse = lazy(() => import('../modules/ReputationMangement/ReviewResponse'));
const SegmentAnalysis = lazy(() => import('../modules/ReputationMangement/SentimentAnalysis'));
const ViewMapping = lazy(() => import('../modules/SSOMapping/ViewMapping'));
const UserSupport = lazy(() => import('../modules/Support/UserSupport'));
const UserWebsiteSupport = lazy(() => import('../modules/Website/UserWebsiteSupport'));
const Imprints = lazy(() => import('../modules/Imprints/Imprints'));
const Arclight = lazy(() => import('../modules/Imprints/Arclight'));
const BusinessCard = lazy(() => import('../modules/Imprints/BusinessCard'));
const CedPortal = lazy(() => import('../modules/Imprints/CedPortal'));
const Flyer = lazy(() => import('../modules/Imprints/Flyer'));
const SelectLocation = lazy(() => import('../modules/Website/SelectLocation'));
const CreateTicket = lazy(() => import('../modules/Support/CreateTicket'));
const AccountSetting = lazy(() => import('../modules/UserAccount/AccountSetting'));
const SignUp = lazy(() => import('../modules/Front/SignUp'));
const ViewLocation = lazy(() => import('../modules/ManageBusinessListing/ViewLocation'));
const SocailInsight = lazy(() => import('../modules/DashboardAdmin/SocailInsight'));
const ReviewByMonth = lazy(() => import('../modules/DashboardAdmin/ReviewByMonth'));
const Listing = lazy(() => import('../modules/DashboardAdmin/Listing'));
const ReputationDashboard = lazy(() => import('../modules/DashboardAdmin/ReputationDashboard'));
const CEDLogin = lazy(() => import('../modules/Front/CEDLogin'));
const GenerateCedLoginToken = lazy(() => import('../modules/Website/GenerateCedLoginToken'));
const CedCustomPortalList = lazy(() => import('../modules/Imprints/CedCustomPortalList'));
const ArclightList = lazy(() => import('../modules/Imprints/ArclightList'));
const BusinessCardList = lazy(() => import('../modules/Imprints/BusinessCardList'));
const VmiFlyerList = lazy(() => import('../modules/Imprints/VmiFlyerList'));
const SettingPanel = lazy(() => import('../modules/SettingPanel/SettingPanel'));
const ManageAdmins = lazy(() => import('../modules/ManageAdmins/ManageAdmins'));
const GMBBusinessLocation = lazy(() => import('../modules/GMBBusinessLocation/GMBBusinessLocation'));
const ActiveLocation = lazy(() => import('../modules/GMBBusinessLocation/ActiveLocation'));
const OnBoardingList = lazy(() => import('../modules/Onboarding/OnboardingList'));
const NewHome = lazy(() => import('../modules/Front/NewHome'));
const ScanYourBusiness = lazy(() => import('../modules/Front/ScanYourBusiness'));
const RequestDemo = lazy(() => import('../modules/Front/RequestDemo'));
const DigitalContentService = lazy(() => import('../modules/Front/DigitalContentServices'));
const Manage = lazy(() => import('../modules/SocialMediaManager/Manage'));
const PostingApproval = lazy(() => import('../modules/SocialMediaManager/PostingApproval'));
const DigitalIntegration = lazy(() => import('../modules/Front/digital-integration'));
const WebsitesTemplates = lazy(() => import('../modules/Front/WebsitesTemplates'));
const BrandDesignServices = lazy(() => import('../modules/Front/DigitalContent'));
const SearchEngineOptimization = lazy(() => import('../modules/Front/SearchEngineOptimization'));
const PpcWebsiteDesign = lazy(() => import('../modules/Front/PpcWebsiteDesign'));


export default function Routing() {

    let token = localStorage.getItem('amazio-token');
    const [context, setContext] = useState(JSON.stringify({ token: token, auth: [] }));
    const [routLoader, setRoutLoader] = useState(false);
    const navigate = useNavigate();
    const location = useLocation();

    const AmazioServiceUsed = context.auth?.current_service;

    useEffect(() => {
        window.scroll({ top: 0, left: 0, behavior: 'instant' });
    }, [location.pathname])

    // useLayoutEffect(() => {
    //     if ( location.pathname?.match('/blogs/*')) {
    //         window.location.href =('https://blog.amazio.com/case-studies')
    //     }
    //     if (location.pathname == '/blogs') {
    //         window.location.href =('https://blog.amazio.com/')
    //     }
    //     if ( location.pathname?.match('/case-studies')) {
    //         window.location.href =('https://blog.amazio.com/case-studies')
    //     }
    // }, [location.pathname])

    useEffect(() => {
        if (token) {
            fetchData(GET_AUTH_USER_INFO, 'GET', '', true, false, (res) => {
                if (res.records) {
                    setContext(prevState => ({
                        ...prevState,
                        auth: res.records
                    }))
                }
                setRoutLoader(true);
            });

        } else {
            if (location.pathname.match('/admin/*') || location.pathname.match('/user/*')) {
                navigate('/log-in')
            }
        }


    }, [token, location, navigate]);

    return (
        <Context.Provider value={[context, setContext]} >
            <Routes>
                {/* Start | Front Pages */}
                <Route path="/" caseSensitive={false} element={<LayoutOfFront />}>

                    {/* <Route path="/test" caseSensitive={false} element={<Test />} /> */}
                    {AmazioServiceUsed == 'soci' &&
                        <Route path="/home" caseSensitive={false} element={<Home />} />
                    }
                    {AmazioServiceUsed == 'yext' &&
                        <Route path="/home" caseSensitive={false} element={<Home />} />
                    }
                    <Route path="/" caseSensitive={false} element={<Suspense fallback={<PageLoader />}><NewHome /></Suspense>} />
                    <Route path="/services" caseSensitive={false} element={<Suspense fallback={<PageLoader />}><AllServices /></Suspense>} />
                    <Route path="/industries" caseSensitive={false} element={<Suspense fallback={<PageLoader />}><Industries /></Suspense>} />
                    <Route path="/plans-pricing" caseSensitive={false} element={<Suspense fallback={<PageLoader />}><Pricing /></Suspense>} />
                    <Route path="/business-listing-management-usa" caseSensitive={false} element={<Suspense fallback={<PageLoader />}><Digital /></Suspense>} />
                    <Route path="/branding-design-services" caseSensitive={false} element={<Suspense fallback={<PageLoader />}><BrandDesignServices /></Suspense>} />
                    <Route path="/social-media-management" caseSensitive={false} element={<Suspense fallback={<PageLoader />}><SocialMedia /></Suspense>} />
                    <Route path="/digital-content-services" caseSensitive={false} element={<Suspense fallback={<PageLoader />}><DigitalContentService /></Suspense>} />
                    <Route path="/search-engine-optimization" caseSensitive={false} element={<Suspense fallback={<PageLoader />}><SearchEngineOptimization /></Suspense>} />
                    <Route path="/pay-per-click" caseSensitive={false} element={<Suspense fallback={<PageLoader />}><PpcWebsiteDesign /></Suspense>} />
                    <Route path="/industries/hospitality" caseSensitive={false} element={<Suspense fallback={<PageLoader />}><Hospitality /></Suspense>} />
                    <Route path="/industries/retail" caseSensitive={false} element={<Suspense fallback={<PageLoader />}><Retail /></Suspense>} />
                    <Route path="/industries/food" caseSensitive={false} element={<Suspense fallback={<PageLoader />}><Food /></Suspense>} />
                    <Route path="/industries/healthcare" caseSensitive={false} element={<Suspense fallback={<PageLoader />}><Healthcare /></Suspense>} />
                    <Route path="/industries/insurance" caseSensitive={false} element={<Suspense fallback={<PageLoader />}><Insurance /></Suspense>} />
                    <Route path="/industries/government" caseSensitive={false} element={<Suspense fallback={<PageLoader />}><Government /></Suspense>} />
                    <Route path="/industries/franchises" caseSensitive={false} element={<Suspense fallback={<PageLoader />}><Franchises /></Suspense>} />
                    <Route path="/industries/automotive" caseSensitive={false} element={<Suspense fallback={<PageLoader />}><Automotive /></Suspense>} />
                    <Route path="/industries/small-business" caseSensitive={false} element={<Suspense fallback={<PageLoader />}><SmallBusiness /></Suspense>} />
                    <Route path="/knowledge-base" caseSensitive={false} element={<Suspense fallback={<PageLoader />}><KnowledgeBase /></Suspense>} />
                    <Route path="/request-demo" caseSensitive={false} element={<Suspense fallback={<PageLoader />}><RequestDemo /></Suspense>} />
                    <Route path="/web-app-development-maintenance" caseSensitive={false} element={<Suspense fallback={<PageLoader />}><Websites /></Suspense>} />
                    <Route path="/competitive-intelligence-insights-analytics" caseSensitive={false} element={<Suspense fallback={<PageLoader />}><CompetitiveAnalytics /></Suspense>} />
                    <Route path="/print-media" caseSensitive={false} element={<Suspense fallback={<PageLoader />}><PrintMedia /></Suspense>} />
                    <Route path="/facebook-setup" caseSensitive={false} element={<Suspense fallback={<PageLoader />}><FacebookSetup /></Suspense>} />
                    <Route path="/gmb-verification-process" caseSensitive={false} element={<Suspense fallback={<PageLoader />}><GoogleBusinessVerification /></Suspense>} />
                    <Route path="/log-in" caseSensitive={false} element={<Suspense fallback={<PageLoader />}><Login /></Suspense>} />
                    <Route path="/sign-up" caseSensitive={false} element={<Suspense fallback={<PageLoader />}><SignUp /></Suspense>} />
                    <Route path="/reset-password" caseSensitive={false} element={<Suspense fallback={<PageLoader />}><ResetPassword /></Suspense>} />
                    <Route path="/cookie-policy" caseSensitive={false} element={<Suspense fallback={<PageLoader />}><CookiePolicy /></Suspense>} />
                    <Route path="/privacy-policy" caseSensitive={false} element={<Suspense fallback={<PageLoader />}><PrivacyPolicy /></Suspense>} />
                    <Route path="/terms-conditions" caseSensitive={false} element={<Suspense fallback={<PageLoader />}><TermsAndConditions /></Suspense>} />
                    <Route path="/contact-us" caseSensitive={false} element={<Suspense fallback={<PageLoader />}><ContactUs /></Suspense>} />
                    <Route path="/about-us" caseSensitive={false} element={<Suspense fallback={<PageLoader />}><AboutUs /></Suspense>} />
                    <Route path="/reviews-reputation-sentiment-analysis" caseSensitive={false} element={<Suspense fallback={<PageLoader />}><Reputation /></Suspense>} />
                    <Route path="/onboarding-form" caseSensitive={false} element={<Suspense fallback={<PageLoader />}><OnboardingForm /></Suspense>} />
                    <Route path="/resources" caseSensitive={false} element={<Suspense fallback={<PageLoader />}><Resources /></Suspense>} />
                    <Route path="/cedlogin" caseSensitive={false} element={<Suspense fallback={<PageLoader />}><CEDLogin /></Suspense>} />
                    <Route path="/generate-ced-login-token" caseSensitive={false} element={<Suspense fallback={<PageLoader />}><GenerateCedLoginToken /></Suspense>} />
                    <Route path="/scan-your-business" caseSensitive={false} element={<Suspense fallback={<PageLoader />}><ScanYourBusiness /></Suspense>} />
                    <Route path="/integrated-digital-marketing" caseSensitive={false} element={<Suspense fallback={<PageLoader />}><DigitalIntegration /></Suspense>} />
                    <Route path="/web-templates" caseSensitive={false} element={<Suspense fallback={<PageLoader />}><WebsitesTemplates /></Suspense>} />
                    {/* new redirected routes */}



                </Route>
                {/* End | Front Pages */}

                {context && context.auth &&
                    <Route path={context.auth.user_group_id === 1 ? 'admin' : 'users'} caseSensitive={false} element={<LayoutOfAsideAndHeader />}>

                        <Route path="choose-location" caseSensitive={false} element={<Suspense fallback={<PageLoader backend={true} />}><Dashboard /></Suspense>} />
                        <Route path="reputation-dashboard" caseSensitive={false} element={<ProtectedRoute permission="Reputation Management Reviews"><Suspense fallback={<PageLoader backend={true} />}><ReputationDashboard /></Suspense></ProtectedRoute>} />
                        {context.auth.super_admin === 'yes' && <>
                            <Route path="manage-admins-list" caseSensitive={false} element={<Suspense fallback={<PageLoader backend={true} />}><ManageAdmins /></Suspense>} />
                        </>}
                        <Route path="manage-users-list" caseSensitive={false} element={context.auth.user_group_id === 1 ? <ProtectedRoute permission="Manage Users"><Suspense fallback={<PageLoader backend={true} />}><ManageUsers /></Suspense></ProtectedRoute> : <ErrorPage />} />
                        <Route path="sale-agent-list" caseSensitive={false} element={<ProtectedRoute permission="Sales Agent"><Suspense fallback={<PageLoader backend={true} />}><SalesAgent /></Suspense></ProtectedRoute>} />

                        {AmazioServiceUsed == 'soci' &&
                            (
                                <>
                                    {/* :: dev note: need to change SocialManagementListing permission :: */}
                                    <Route path="manage-business-list/:type" caseSensitive={false} element={<ProtectedRoute permission="Business listing"><Suspense fallback={<PageLoader backend={true} />}><SociManageBusinessListing /></Suspense></ProtectedRoute>} />
                                    <Route path="social-media-listing/:type" caseSensitive={false} element={<ProtectedRoute permission="Social Media Dashboard"><Suspense fallback={<PageLoader backend={true} />}><SociManageBusinessListing /></Suspense></ProtectedRoute>} />
                                    {/* Only for testing afterwords delete and use the above line */}

                                    {/* need to change permission  */}
                                    <Route path="review-response" caseSensitive={false} element={<ProtectedRoute permission="Reputation Management Reviews"><Suspense fallback={<PageLoader backend={true} />}><SociReviewResponse /></Suspense></ProtectedRoute>} />
                                </>
                            )
                        }
                        {AmazioServiceUsed == 'yext' &&
                            <Route path="manage-business-list" caseSensitive={false} element={<ProtectedRoute permission="Manage Location"><Suspense fallback={<PageLoader backend={true} />}><ManageBusinessListing /></Suspense></ProtectedRoute>} />
                        }
                        {/* <Route path="manage-business-list" caseSensitive={false} element={<ProtectedRoute permission="Manage Location"><Suspense fallback={<PageLoader backend={true} />}><ManageBusinessListing /></Suspense></ProtectedRoute>} /> */}

                        <Route path='view-tickets-list' caseSensitive={false} element={<ProtectedRoute permission="Supports"><Suspense fallback={<PageLoader backend={true} />}><Support /></Suspense></ProtectedRoute>} />

                        <Route path="rate-us-list" caseSensitive={false} element={<ProtectedRoute permission="Rate Us"><Suspense fallback={<PageLoader backend={true} />}><RateUs /></Suspense></ProtectedRoute>} />
                        <Route path="request-demo-list" caseSensitive={false} element={<ProtectedRoute permission="Demo Requests"><Suspense fallback={<PageLoader backend={true} />}><DemoRequest /></Suspense></ProtectedRoute>} />
                        <Route path="view-website-support" caseSensitive={false} element={<ProtectedRoute permission="Website Admin"><Suspense fallback={<PageLoader backend={true} />}><WebsiteSupport /></Suspense></ProtectedRoute>} />
                        <Route path="faqs-list" caseSensitive={false} element={<ProtectedRoute permission="Manage FAQs"><Suspense fallback={<PageLoader backend={true} />}><ManageFaq /></Suspense></ProtectedRoute>} />
                        <Route path="add-faqs" caseSensitive={false} element={<Suspense fallback={<PageLoader backend={true} />}><AddFaq /></Suspense>} />
                        <Route path="add-unique-url" caseSensitive={false} element={<ProtectedRoute permission="Onboarding"><Suspense fallback={<PageLoader backend={true} />}><Onboarding /></Suspense></ProtectedRoute>} />
                        <Route path="request-to-call" caseSensitive={false} element={<ProtectedRoute permission="Request to call"><Suspense fallback={<PageLoader backend={true} />}><RequestCallBack /></Suspense></ProtectedRoute>} />
                        {AmazioServiceUsed == 'soci' &&
                            <>
                                <Route path="admin-dashboard" caseSensitive={false} element={<ProtectedRoute permission="Common Dashboard"><Suspense fallback={<PageLoader backend={true} />}><SociDashboardAdmin /></Suspense></ProtectedRoute>} />
                                <Route path="social-dashboard" caseSensitive={false} element={<ProtectedRoute permission="Social Media Dashboard"><Suspense fallback={<PageLoader backend={true} />}><SociSocialInsight /></Suspense></ProtectedRoute>} />
                                <Route path="competitive-analysis" caseSensitive={false} element={<ProtectedRoute permission="Reputation Management Reviews"><Suspense fallback={<PageLoader backend={true} />}><SociCompetitiveAnalysis /></Suspense></ProtectedRoute>} />
                                <Route path="review-response" caseSensitive={false} element={<ProtectedRoute permission="Reputation Management Reviews"><Suspense fallback={<PageLoader backend={true} />}><SociReviewResponse /></Suspense></ProtectedRoute>} />
                                <Route path="dashboard-reputation" caseSensitive={false} element={<ProtectedRoute permission="Reputation Management Reviews"><Suspense fallback={<PageLoader backend={true} />}><SociReviewResponse /></Suspense></ProtectedRoute>} />
                                <Route path="sentiment-analysis" caseSensitive={false} element={<ProtectedRoute permission="Reputation Management Reviews"><Suspense fallback={<PageLoader backend={true} />}><SociSegmentAnalysis /></Suspense></ProtectedRoute>} />
                            </>
                        }
                        {/* <Route path="soci-dashboard" caseSensitive={false} element={<ProtectedRoute permission="Social Media Dashboard"><SocailInsight /></ProtectedRoute>} /> */}
                        {AmazioServiceUsed == 'yext' &&
                            <>
                                <Route path="admin-dashboard" caseSensitive={false} element={<ProtectedRoute permission="Common Dashboard"><Suspense fallback={<PageLoader backend={true} />}><DashboardAdmin /></Suspense></ProtectedRoute>} />
                                <Route path="soci-dashboard" caseSensitive={false} element={<ProtectedRoute permission="Social Media Dashboard"><Suspense fallback={<PageLoader backend={true} />}><SocailInsight /></Suspense></ProtectedRoute>} />
                                <Route path="competitive-analysis" caseSensitive={false} element={<ProtectedRoute permission="Reputation Management Reviews"><Suspense fallback={<PageLoader backend={true} />}><CompetitiveAnalysis /></Suspense></ProtectedRoute>} />
                                <Route path="review-response" caseSensitive={false} element={<ProtectedRoute permission="Review Respond"><Suspense fallback={<PageLoader backend={true} />}><ReviewResponse /></Suspense></ProtectedRoute>} />
                                <Route path="sentiment-analysis" caseSensitive={false} element={<ProtectedRoute permission="Reputation Management Reviews"><Suspense fallback={<PageLoader backend={true} />}><SegmentAnalysis /></Suspense></ProtectedRoute>} />
                            </>
                        }
                        <Route path="dashboard-listing" caseSensitive={false} element={<Suspense fallback={<PageLoader backend={true} />}><Listing /></Suspense>} />
                        <Route path="soci-dashboard-review-by-month" caseSensitive={false} element={<Suspense fallback={<PageLoader backend={true} />}><ReviewByMonth /></Suspense>} />
                        <Route path="pricing-list" caseSensitive={false} element={<ProtectedRoute permission="Manage Plan & Prices"><Suspense fallback={<PageLoader backend={true} />}><ManagePlan /></Suspense></ProtectedRoute>} />
                        <Route path="book-keeping" caseSensitive={false} element={<ProtectedRoute permission="Book Keeping"><Suspense fallback={<PageLoader backend={true} />}><BookKeeping /></Suspense></ProtectedRoute>} />
                        <Route path="promo-code" caseSensitive={false} element={<ProtectedRoute permission="Promo Code"><Suspense fallback={<PageLoader backend={true} />}><PromoCode /></Suspense></ProtectedRoute>} />
                        <Route path="manage-subscribers-list" caseSensitive={false} element={<ProtectedRoute permission="Manage Subscribers"><Suspense fallback={<PageLoader backend={true} />}><ManageSubscriber /></Suspense></ProtectedRoute>} />
                        <Route path="user-activity" caseSensitive={false} element={<ProtectedRoute permission="User Activity"><Suspense fallback={<PageLoader backend={true} />}><UserActivity /></Suspense></ProtectedRoute>} />
                        <Route path="activity-logs" caseSensitive={false} element={<ProtectedRoute permission="Logs Activity"><Suspense fallback={<PageLoader backend={true} />}><ActivityLog /></Suspense></ProtectedRoute>} />
                        <Route path="sso-mapping" caseSensitive={false} element={<ProtectedRoute permission="CED Mapping"><Suspense fallback={<PageLoader backend={true} />}><ViewMapping /></Suspense></ProtectedRoute>} />
                        <Route path="payment-invoice-list" caseSensitive={false} element={<Suspense fallback={<PageLoader backend={true} />}><PaymentInvoice /></Suspense>} />
                        <Route path="change-password" caseSensitive={false} element={<ProtectedRoute permission="Profile"><Suspense fallback={<PageLoader backend={true} />}><ChangePassword /></Suspense></ProtectedRoute>} />
                        <Route path="my-profile" caseSensitive={false} element={<ProtectedRoute permission="Profile"><Suspense fallback={<PageLoader backend={true} />}><MyProfile /></Suspense></ProtectedRoute>} />
                        <Route path="manage" caseSensitive={false} element={<ProtectedRoute permission="Social Media Manager"><Suspense fallback={<PageLoader backend={true} />}><Manage /></Suspense></ProtectedRoute>} />
                        <Route path="posting-approval" caseSensitive={false} element={<ProtectedRoute permission="Posting Approval"><Suspense fallback={<PageLoader backend={true} />}><PostingApproval /></Suspense></ProtectedRoute>} />
                        <Route path="reports" caseSensitive={false} element={<ProtectedRoute permission="Reports"><Suspense fallback={<PageLoader backend={true} />}><Report /></Suspense></ProtectedRoute>} />
                        <Route path="view-location/:location_id/:site_status" caseSensitive={false} element={<ProtectedRoute permission="Business listing"><Suspense fallback={<PageLoader backend={true} />}><ViewLocation /></Suspense></ProtectedRoute>} />
                        <Route path="view-location" caseSensitive={false} element={<ProtectedRoute permission="Business listing"><Suspense fallback={<PageLoader backend={true} />}><ViewLocation /></Suspense></ProtectedRoute>} />
                        <Route path="manage-gmb-location" caseSensitive={false} element={<ProtectedRoute permission="GMB Business Location"><Suspense fallback={<PageLoader backend={true} />}><GMBBusinessLocation /></Suspense></ProtectedRoute>} />
                        <Route path="active-locations" caseSensitive={false} element={<ProtectedRoute permission="GMB Active Location"><Suspense fallback={<PageLoader backend={true} />}><ActiveLocation /></Suspense></ProtectedRoute>} />
                        {(context.auth.super_admin === 'no' && context.auth.user_group_id === 1) || context.auth.super_admin === 'yes' && <>
                            <Route path="ced-custom-portal" caseSensitive={false} element={<ProtectedRoute permission="Imprints"><Suspense fallback={<PageLoader backend={true} />}><CedCustomPortalList /></Suspense></ProtectedRoute>} />
                            <Route path="arclight-list" caseSensitive={false} element={<ProtectedRoute permission="Imprints"><Suspense fallback={<PageLoader backend={true} />}><ArclightList /></Suspense></ProtectedRoute>} />
                            <Route path="business-card-list" caseSensitive={false} element={<ProtectedRoute permission="Imprints"><Suspense fallback={<PageLoader backend={true} />}><BusinessCardList /></Suspense></ProtectedRoute>} />
                            <Route path="vmi-flyer-list" caseSensitive={false} element={<ProtectedRoute permission="Imprints"><Suspense fallback={<PageLoader backend={true} />}><VmiFlyerList /></Suspense></ProtectedRoute>} />
                            <Route path="setting-panel" caseSensitive={false} element={<Suspense fallback={<PageLoader backend={true} />}><SettingPanel /></Suspense>} />

                        </>}

                        {/* User Route */}
                        <Route path="account-setting" caseSensitive={false} element={<Suspense><AccountSetting /></Suspense>} />
                        <Route path="support" caseSensitive={false} element={<Suspense><UserSupport /></Suspense>} />
                        <Route path="website-support" caseSensitive={false} element={<ProtectedRoute permission="Website"><Suspense><UserWebsiteSupport /></Suspense></ProtectedRoute>} />
                        <Route path="imprints" caseSensitive={false} element={<ProtectedRoute permission="Imprints"><Suspense><Imprints /></Suspense></ProtectedRoute>} />
                        <Route path="ced-portal" caseSensitive={false} element={<ProtectedRoute permission="Imprints"><Suspense><CedPortal /></Suspense></ProtectedRoute>} />
                        <Route path="arclight" caseSensitive={false} element={<ProtectedRoute permission="Imprints"><Suspense><Arclight /></Suspense></ProtectedRoute>} />
                        <Route path="business-card" caseSensitive={false} element={<ProtectedRoute permission="Imprints"><Suspense><BusinessCard /></Suspense></ProtectedRoute>} />
                        <Route path="flyer" caseSensitive={false} element={<ProtectedRoute permission="Imprints"><Suspense><Flyer /></Suspense></ProtectedRoute>} />
                        <Route path="select-location" caseSensitive={false} element={<Suspense><SelectLocation /></Suspense>} />
                        <Route path="create-ticket" caseSensitive={false} element={<ProtectedRoute permission="Supports"><Suspense><CreateTicket /></Suspense></ProtectedRoute>} />
                        <Route path="ced-custom-portal" caseSensitive={false} element={<ProtectedRoute permission="Imprints"><Suspense><CedCustomPortalList /></Suspense></ProtectedRoute>} />
                        <Route path="arclight-list" caseSensitive={false} element={<ProtectedRoute permission="Imprints"><Suspense><ArclightList /></Suspense></ProtectedRoute>} />
                        <Route path="business-card-list" caseSensitive={false} element={<ProtectedRoute permission="Imprints"><Suspense><BusinessCardList /></Suspense></ProtectedRoute>} />
                        <Route path="vmi-flyer-list" caseSensitive={false} element={<ProtectedRoute permission="Imprints"><Suspense><VmiFlyerList /></Suspense></ProtectedRoute>} />
                        <Route path="onboarding-details" caseSensitive={false} element={<Suspense><OnBoardingList /></Suspense>} />
                        <Route path="*" element={<ErrorPage />} />
                    </Route>}
                <Route path="/404" element={<Error404 />} />
                <Route path="/301" element={<Error301 />} />
                <Route path="/302" element={<Error302 />} />
                {(!token || routLoader) && <Route path="*" element={<ErrorPage />} />}
            </Routes>
        </Context.Provider>
    )
}
